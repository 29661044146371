import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_approved = _resolveComponent("approved", true)!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_approved)
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "message" }, "Access integration granted", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "subtext" }, " The permissions for the integration have been successfully granted. ", -1)),
      _createVNode(_component_router_link, { to: "/dashboard/applications" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("a", { class: "link" }, "Go to Mekari Developer Center", -1)
        ])),
        _: 1
      })
    ])
  ]))
}