import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "wrapper"
}
const _hoisted_2 = {
  key: 1,
  class: "wrapper"
}
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "logo-list" }
const _hoisted_5 = { class: "scopes" }
const _hoisted_6 = { class: "footer" }
const _hoisted_7 = { class: "actions" }
const _hoisted_8 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Loading..."))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.generateLogo(), (componentName, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "logo",
                key: index
              }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(componentName)))
              ]))
            }), 128))
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("div", {
            class: "content scrollable",
            onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleScroll && _ctx.handleScroll(...args))),
            ref: "scrollable"
          }, [
            _createElementVNode("h1", null, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.$store.state.access_grant.accessGrant?.requestorApp), 1),
              _cache[2] || (_cache[2] = _createTextVNode(" is requesting integration to ")),
              _createElementVNode("b", null, _toDisplayString(_ctx.products), 1),
              _cache[3] || (_cache[3] = _createTextVNode(" with this following permissions: "))
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Data access", -1)),
            _createElementVNode("ul", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredScopes, (value, key) => {
                return (_openBlock(), _createElementBlock("li", { key: key }, [
                  _createElementVNode("h3", null, _toDisplayString(key), 1),
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value, (item) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: item.id
                      }, _toDisplayString(item.description_id), 1))
                    }), 128))
                  ])
                ]))
              }), 128))
            ]),
            _cache[5] || (_cache[5] = _createElementVNode("h2", null, "Continuous access", -1)),
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, _toDisplayString(_ctx.$store.state.access_grant.accessGrant?.requestorApp) + " may access data even when you are not actively using " + _toDisplayString(_ctx.products), 1)
            ])
          ], 544),
          _cache[9] || (_cache[9] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("div", _hoisted_6, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "footer-links" }, [
              _createElementVNode("p", null, [
                _createTextVNode(" Learn about Mekari’s "),
                _createElementVNode("a", { href: "https://mekari.com/privacy/" }, "Privacy Policy"),
                _createTextVNode(" and "),
                _createElementVNode("a", { href: "https://mekari.com/persyaratan-layanan/" }, "Terms of Service")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_router_link, {
                class: "btn btn-transparent",
                to: `/dashboard/applications`,
                style: { textDecoration: 'none' }
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" Cancel ")
                ])),
                _: 1
              }),
              _createElementVNode("button", {
                disabled: _ctx.isDisabledBtn,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.approve(_ctx.uuid))),
                type: "button",
                class: "btn btn-primary field-submit"
              }, " Allow ", 8, _hoisted_8)
            ])
          ])
        ])
      ]))
}